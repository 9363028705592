import './App.css';
import Mgm from './Components/Mgm';

function App() {
   return (
      <>
         <Mgm/>
      </>
   );
}

export default App;
