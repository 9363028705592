import React, { useEffect } from 'react'
import useWindowSize from './useWindowSize'
import './Mgm.css';
import Bg from '../Assets/Group 3.png'
import BgMobile from '../Assets/bgMobile.png'
import Medisage from '../Assets/mnedisage.svg'
import Logo from '../Assets/logo.svg'
import Title from '../Assets/Group.svg'
import MobileTitle from '../Assets/mobileTitle.svg'
import Time from '../Assets/time.svg'
import TimeMobile from '../Assets/timeMobile.svg'
import Masters from '../Assets/masters.svg'
import MastersMobile from '../Assets/mastersMobile.svg'
import Judge1 from '../Assets/judge1.svg'
import Judge2 from '../Assets/judge2.svg'
import Qr from '../Assets/qr.svg'
import ButtonIcon from '../Assets/button.svg'

function Mgm() {
   const windowSize = useWindowSize();
   return (
      <div className='container'>
         <div className="main">
            <div className="header">
               <img src={Medisage} alt="" className='medisage-logo' />
               <img src={Logo} alt="" className='logo' />
            </div>
            <div className="titleDiv">
               {
                  windowSize > 700 ? <img src={Title} alt="" className='title' /> : <img src={MobileTitle} alt="" className='title' />
               }

               <div className="host">
                  <span className='hostedTitle'>Hosted by MGM Medical College</span>
               </div>
            </div>
            <div className="body">
               <div className="timeContent">
                  <div className='descDiv'>
                     <p className='desc'>
                        MGM Diabetes Update 2023 & Inter Medical<br />
                        College Diabetes Quiz Competition
                     </p>
                  </div>
                  <div className="timeVenue">
                     {
                        windowSize > 700 ? <img src={Time} alt="" className='time' /> : <img src={TimeMobile} alt="" className='time' />
                     }
                  </div>
               </div>
               <div className="judgesMasters">
                  <div className="masters">
                     <p className='text'>Quiz Masters</p>
                     <div className="masterCards">
                        {
                           windowSize > 700 ? <img src={Masters} alt="" className='master-logo' /> : <img src={MastersMobile} alt="" className='master-logo' />
                        }
                     </div>
                  </div>
                  <div className="judges">
                     <p className='text'>Quiz Judges</p>
                     <div className="jugesCards">
                        <div className="judge1">
                           <img src={Judge1} alt="" className='jugdeImage' /><br></br>
                           <span className='dr-name'>Dr. Manoj Chawla</span>
                        </div>
                        <div className="judge1">
                           <img src={Judge2} alt="" className='jugdeImage' /><br></br>
                           <span className='dr-name'>Dr. Girish Rajadhyaksha</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer">
                  <div className="qr-div">
                     <img src={Qr} alt="" className='qr' />
                  </div>
                  <div className="button">
                     <button>
                        <a href="https://mymedisage.com/download" className='link'>
                           <img src={ButtonIcon} alt="" className='buttonlogo' />
                           Register Now!
                        </a>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Mgm